import { graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { TextContent } from '~/components/flexible/TextContent';
import { MaybeLink } from '~/components/elements/Link';
import { useIntegrationsCategories } from '~/hooks/useIntegrationsCategories';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { data } from 'autoprefixer';
import { AnimatePresence, motion } from 'framer-motion';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';

export default function IntegrationPage({ data: { wpIntegration, resourcesPage, wp } }) {
  const _categories = useIntegrationsCategories();
  const categories = [];
  ['ERP', 'CRM', 'HRIS', 'BILLING'].map((cat) => {
    _categories.map((_cat) => {
      if (_cat.taxonomyIntegrationCategory.shortName === cat) {
        categories.push(_cat);
      }
    });
  });
  return (
    <Layout wp={wp}>
      <Seo post={wpIntegration} />
      <IntegrationHeader {...wpIntegration.integration} />
      <IntegrationFeatures {...wpIntegration.integration} />
      <IntegrationBlocks {...wpIntegration.integration} />
      <MoreIntegrations {...wpIntegration.integration} categories={categories} />
      <LayoutRenderer
        prefix="Page_Posttypepage_Sections"
        sections={resourcesPage?.postTypePage?.sections?.filter(
          (section) => section?.fieldGroupName === 'Page_Posttypepage_Sections_ReusableBlock'
        )}
      />
    </Layout>
  );
}

export const IntegrationHeader = ({ name, pageTitle, description, asset }) => {
  return (
    <div className="bg-lightGrey overflow-hidden">
      <InViewAnimator
        className="section"
        inner={(controls) => (
          <div className="container md:flex md:flex-row-reverse md:justify-between items-center">
            <InViewAnimatorBasicItem
              className="md:w-[45%] xl:w-[52%] md:pl-6 mt-10 xl:mt-36 md:ml-[62px]"
              controls={controls}
              delay={0.35}
              prop="x"
              start={120}
            >
              <MediaAssetInner className="md:ml-auto w-[139%] max-w-none" {...asset} />
            </InViewAnimatorBasicItem>
            <InViewAnimatorBasicItem
              className="flex-1 max-w-[550px] mx-auto md:mx-0 pt-6 md:pb-16 md:pt-12 lg:pt-24 lg:pb-24 xl:pt-32 xl:pb-36"
              controls={controls}
              delay={0}
              prop="y"
              start={50}
            >
              <TextContent
                innerOnly={true}
                headingSize="52"
                centered={false}
                isMasthead={true}
                linkStyle="button"
                subheadingStyle="plain"
                centered={false}
                subheading={`${name} & Mosaic`}
                heading={name}
                content={description}
              />
            </InViewAnimatorBasicItem>
          </div>
        )}
      />
    </div>
  );
};

export const IntegrationFeatures = (props) => {
  const features = [1, 2, 3].map((i) => {
    return {
      icon: props[`feature${i}Icon`],
      image: props[`feature${i}Image`],
      title: props[`feature${i}Title`],
      description: props[`feature${i}Description`],
    };
  });
  const { featuresTitle, featuresSubtitle } = props;

  return (
    <section className="section section-top container">
      {featuresTitle && (
        <div className="pb-12 text-center space-y-6 md:pb-20">
          <h2 className="t-subheading">{featuresTitle}</h2>
          <h3 className="t-42">{featuresTitle}</h3>
        </div>
      )}
      <InViewAnimator
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-[53px]"
        inner={(controls) => (
          <>
            {features.map(({ icon, title, description }, i) => (
              <InViewAnimatorBasicItem
                controls={controls}
                delay={i / 5}
                prop="y"
                start={50}
                className="text-center md:text-left space-y-4 md:space-y-[22px]"
                key={i}
              >
                {icon && icon.image && (
                  <div className="fixed-height-icon h-[35px]">
                    <MediaAssetInner className="mx-auto md:mx-0" {...icon} />
                  </div>
                )}
                <h4 className="t-20-heading font-semibold pt-2.5 text-primaryText">{title}</h4>
                <p className="t-17 opacity-90 text-secondaryText">{description}</p>
              </InViewAnimatorBasicItem>
            ))}
          </>
        )}
      />
    </section>
  );
};

export const IntegrationBlocks = ({ blocks }) => {
  if (!blocks) return null;
  return (
    <section className="section container space-y-[120px] md:space-y-[212px]">
      {blocks.map((block, i) => (
        <IntegrationBlock {...block} flip={i % 2} key={i} />
      ))}
    </section>
  );
};

export const IntegrationBlock = ({ main, image, flip }) => (
  <div className={`md:flex justify-between items-center ${flip ? 'flex-row-reverse' : ''}`}>
    <div className={`md:w-[50%] ${flip ? 'md:ml-8' : 'md:mr-8'}`}>
      <MediaAssetInner type="image" image={image} />
    </div>
    <div className="mt-8 md:mt-0 space-y-5 flex-1 md:space-y-[22px] max-w-[486px]">
      <h3 className="t-42">{main.heading}</h3>
      <div className="t-20">{main.content}</div>
    </div>
  </div>
);

export const MoreIntegrations = ({ type, categories }) => {
  const [active, setActive] = useState(type);
  const [items, setItems] = useState(categories);
  console.log(active, categories);
  useEffect(() => {
    categories.map((category) => {
      if (category.taxonomyIntegrationCategory.shortName.toLowerCase() === active.toLowerCase()) {
        setItems(() => category.integrations.nodes);
      }
    });
  }, [type]);
  return (
    <section className="section section-bot">
      <div className="container">
        <h3 className="t-42">Explore more integrations</h3>
        <ul className="flex flex-row text-primaryText font-medium gap-[15px] mt-4 md:mt-[30px] transition-colors">
          {categories.map((category, i) => (
            <li
              onClick={() => {
                setItems(category.integrations.nodes);
                setActive(category.taxonomyIntegrationCategory.shortName);
              }}
              className={`relative t-15 px-[22.5px] py-[6.1px] cursor-pointer ${
                category.taxonomyIntegrationCategory.shortName.toLowerCase() === active.toLowerCase()
                  ? 'bg-[#50B5FE] text-white'
                  : 'bg-lightGrey text-primaryText'
              }`}
              key={i}
            >
              <AnimatePresence>
                {category.taxonomyIntegrationCategory.shortName === active && (
                  <motion.div
                    initial={{ width: 0, opacity: 0 }}
                    animate={{ width: '100%', opacity: 1 }}
                    exit={{ width: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="absolute left-0 top-0 bottom-0 bg-[#50B5FE]"
                  ></motion.div>
                )}
              </AnimatePresence>
              <span className="relative">{category.taxonomyIntegrationCategory.shortName}</span>
            </li>
          ))}
        </ul>
        <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-10 md:mt-12">
          {items.map((item, i) => (
            <ExploreItem item={item} active={active} key={i} />
          ))}
        </div>
      </div>
    </section>
  );
};

export const ExploreItem = ({ item, active }) => {
  return (
    <MaybeLink link={{ url: item.link }} className="border-thin-grid p-5">
      <div className="max-w-[158px] h-[36.44px] flex flex-row items-center">
        <MediaAssetInner type="image" image={item.integration?.gridViewLogo} />
      </div>
      <div className="flex flex-row justify-between mt-2.5 items-center">
        <h1 className="t-20-heading">{item.integration?.name}</h1>
        <h5 className="t-10 text-[#50B5FE] bg-headerBlue py-[7px] px-[17px]">{active}</h5>
      </div>
    </MaybeLink>
  );
};

export const pageQuery = graphql`
  query Integration($id: String!) {
    wpIntegration(id: { eq: $id }) {
      ...GeneratedWpIntegration
      ...IntegrationSEO
    }
    resourcesPage: wpPage(id: { eq: "cG9zdDozMzU=" }) {
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
